<template>
<td v-if="!dataItem['inEdit']">
    <div>
        <v-btn
            elevation="0"
            height="32"
            class="
            font-weight-bold
            text-xs
            btn-default
            bg-gradient-primary
            "
            color="#5e72e4"
            @click="editHandler"
            id="contact_edit"
        >
            Edit
        </v-btn>
        <v-btn
            elevation="0"
            height="32"
            class="
            font-weight-bold
            text-xs
            btn-default btn-outline-default
            shadow-none"
            color="transparent"
            id="contact_remove"
            @click="removeHandler">
            Remove
        </v-btn>
    </div>
</td>
<td v-else>
         <v-btn
            elevation="0"
            height="32"
            class="
            font-weight-bold
            text-xs
            btn-default
            bg-gradient-primary
            "
            color="#5e72e4"
            id="contact_add"
            @click="addUpdateHandler">
            {{dataItem.ProductID? 'Update' : 'Add'}}
        </v-btn>
        <v-btn
            elevation="0"
            height="32"
            class="
            font-weight-bold
            text-xs
            btn-default btn-outline-default
            shadow-none"
            color="transparent"
            id="contact_discard"
            @click="cancelDiscardHandler">
            {{dataItem.ProductID? 'Cancel' : 'Discard'}}
        </v-btn>
    </td>
</template>
<script>

export default {
    props: {
        field: String,
        dataItem: Object,
        format: String,
        className: String,
        columnIndex: Number,
        columnsCount: Number,
        rowType: String,
        level: Number,
        expanded: Boolean,
        editor: String
    },
    computed: {
        user: function() {
            return this.$store.getters.currentUser;
        },
    },
    methods: {
        editHandler: function() {
            this.$emit('edit', {dataItem:this.dataItem});
        },
        removeHandler: function() {
            this.$emit('remove', {dataItem:this.dataItem});
        },
        addUpdateHandler: function() {
            this.$emit('save', {dataItem:this.dataItem});
        },
        cancelDiscardHandler: function() {
            this.$emit('cancel', {dataItem:this.dataItem});
        }
    }
}
</script>
<style scoped>
#contact_edit {
    min-width: 0;
    width: 28px;
    float: left;
}
#contact_remove {
    min-width: 0;
    width: 28px;
    margin-left:5%; 
}
#contact_add {
    min-width: 0;
    width: 28px;
    float: left;
}
#contact_discard {
    min-width: 0;
    width: 28px;
    margin-left:5%; 
}
</style>