<template>
  <td>
    <v-icon @click="show()">mdi-account</v-icon>
    <v-dialog
      v-model="showRelationDialog"
      width="500"
    >
      <v-card>
        <v-toolbar color="pink" dark>
          <v-app-bar-nav-icon></v-app-bar-nav-icon>

          <v-toolbar-title>{{ this.contactName }} Relationships</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon>
            <v-icon @click="close()">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-layout align-center justify-center>
          <v-flex class="white" id="relationship_form">
            <v-select
            v-model="relationID"
            :items="relationshipType"
            label="Select Relation"
            >
            </v-select>
            <v-select 
            v-if="this.relationID == 1"
            v-model="relationship"
            :items="customer"
            item-text="name"
            item-value="id"
            label="Select Customer">
            </v-select>
            <v-autocomplete
              v-else-if="this.relationID == 2"
              v-model="relationship"
              :items="account"
              outlined
              item-text="name"
              item-value="id"
              dense
              chips
              small-chips
              label="Select Account"
            ></v-autocomplete>
            <!-- <v-select 
            v-else-if="this.relationID == 2"
            v-model="relationship"
            :items="account"
            item-text="name"
            item-value="id"
            label="Select Account">
            </v-select> -->
            <v-autocomplete
              v-else-if="this.relationID == 3"
              v-model="relationship"
              :items="site"
              outlined
              item-text="name"
              item-value="id"
              dense
              chips
              small-chips
              label="Select Site"
            ></v-autocomplete>
            <!-- <v-select 
            v-else-if="this.relationID == 3"
            v-model="relationship"
            :items="site"
            item-text="name"
            item-value="id"
            label="Select Site"
            >
            </v-select> -->
          </v-flex>
        </v-layout>
        <v-col cols="6">
          <v-btn
            :ripple="false"
            :elevation="0"
            class="
            font-weight-bold
            text-xs
            btn-success
            bg-gradient-success
            ml-4"
            block
            @click="createRelationship()"
          >
            Create Relationship
          </v-btn>
        </v-col>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="5"
            class="elevation-1"
          >
            <!-- TODO: put a few templates here to translate contact name and relation -->
            <template v-slot:item.controls="props">
              <v-btn
                class="mx-2"
                icon
                color="red"
                @click="deleteRelationship(props.item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
      </v-card>
    </v-dialog>
  </td>
</template>
<script>
import Service from "@/services/Service.js";

import Vue from "vue";

export default {
  components: {},
  props: ["customer", "account", "site", "dataItem"],
  data() {
    return {
      contactId: null,
      contactName: null,
      headers: [
        { text: "Relationship Type", value: "relationship_type.name"},
        { text: "Relation", value: "relationship_name"},
        { text: "Remove", value: "controls", sortable: false },
      ],
      items: [],
      relationID: null,
      relationship: null,
      relationshipType: [
        { text: "Customer", value: "1"},
        { text: "Account", value: "2"},
        { text: "Site", value: "3"}
      ],
      showRelationDialog: false,
    };
  },
  computed: {
  },
  methods: {
    
    setContactId() {
      this.contactId = this.dataItem.id;
      this.contactName = this.dataItem.name;
      this.getData(this.contactId)
    },
    getData(contactId) {
      // Here, You only want to load the relationships for this contact, to show in the grid
      Service.getRelations(contactId)
        .then((response) => {
          this.items = response.data.map(r => {
            if (r.relationship_type.name == "customer") {
              let x = this.customer.find((c) => c.id == r.relationship_id);
              r.relationship_name = x.name;
              return r;
            } else if (r.relationship_type.name == "account") {
              let x = this.account.find((a) => a.id == r.relationship_id);
              r.relationship_name = x.name;
              return r;
            } else if (r.relationship_type.name == "site") {
              let x = this.site.find((s) => s.id == r.relationship_id);
              r.relationship_name = x.name;
              return r;
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })
      // TODO: load relationships & bind to items in grid
    },
    createRelationship() {
      const params = {
        contact_id: this.contactId,
        relationship_id: this.relationship,
        relationship_type_id: parseInt(this.relationID),
      }
      Service.createRelation(params)
        .then((response) => {
          this.getData(this.contactId)
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteRelationship(obj) {
      const params = {
        id: obj.id,
      }
      console.log(obj)
      Service.deleteRelation(params)
        .then((response) => {
          this.getData(this.contactId)
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    startNewContactModal() {
      this.newContactModal = true;
    },
    show() {
      this.setContactId();
      this.showRelationDialog = true;
    },
    close() {
      this.showRelationDialog = false;
    }
  }
};
</script>
<style>
#relationship_form {
  margin-top: 2%;
  margin-left: 6%;
  margin-right: 6%;
  margin-bottom: 2%;
}
</style>