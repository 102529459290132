<template>
  <div>
    <h1>Contacts</h1>
    <Grid
      ref="grid"
      :data-items="result"
      :filterable="true"
      :filter="filter"
      @filterchange="filterChange"
      :edit-field="'inEdit'"
      :sortable="{ mode: 'multiple' }"
      :sort="sort"
      @sortchange="sortChangeHandler"
      :pageable="pageable"
      :take="take"
      :skip="skip"
      :total="total"
      @itemchange="itemChange"
      @dataStateChange="dataStateChange"
      :columns="columns"
      @pagechange="pageChange"
    >
      <template v-slot:myTemplate="{ props }">
        <custom
          :data-item="props.dataItem"
          @edit="edit"
          @save="save"
          @remove="remove"
          @cancel="cancel"
        />
      </template>
      <template v-slot:relationshipCell="{ props }">
        <ContactRelationship
        v-if="loaded"
        :data-item="props.dataItem"
        v-bind:customer="customers"
        v-bind:account="accounts"
        v-bind:site="sites"
        />
      </template>
      <grid-toolbar>
        <v-btn
          :disabled="user.claims.user_role == 'customer_view'"
          :ripple="false"
          :elevation="0"
          class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-default" 
          id="add_new_btn" 
          @click="insert"
        >
          Add new
        </v-btn>
        <v-btn
          v-if="hasItemsInEdit"
          :ripple="false"
          :elevation="0"
          class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-danger"
          id="cancel_changes_btn"
          @click="cancelChanges"
        >
          Cancel current changes
        </v-btn>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-default"
          id="export_btn"
          @click="exportExcel"
        >
          Export to Excel
        </v-btn>
      </grid-toolbar>
      <grid-norecords> There is no data available custom </grid-norecords>
    </Grid>
  </div>
</template>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import CommandCell from "./CommandCell";

import Service from "@/services/Service.js";
import ContactRelationship from "./ContactRelationship.vue";
Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    custom: CommandCell,
    ContactRelationship
},
  data: function () {
    return {
      loaded: false,
      accounts: [],
      customers: [],
      filter: null,
      loaded: false,
      sort: null,
      sites: [],
      pageable: {'pageSizes': [10,20,50,100]},
      gridData: [],
      skip: 0,
      take: 20,
      pageSize: 20,
      updatedData: [],
      editID: null,
      sort: [
        { field: "name", dir: "asc" },
      ],
      columns: [
        { field: "id", hidden: true, editable: false, title: "ID" },
        { field: "name", title: "First" },
        { field: "last_name", title: "Last"},
        { field: "email" },
        { field: "phone" },
        { field: "category" },
        { field: "notes" },
        { field: "relationships", title: "Relationships", filterable: false, cell: "relationshipCell"},
        { cell: "myTemplate", title: "Actions", filterable: false, width: "210px" },
      ],
    };
  },
  computed: {
    user: function() {
      return this.$store.getters.currentUser;
    },
    result: {
      get: function () {
        return filterBy(this.gridData, this.filter).slice(
          this.skip,
          this.take + this.skip
        );
      },
    },
    hasItemsInEdit() {
      return this.gridData.filter((p) => p.inEdit).length > 0;
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
  },
  created: function () {
    this.getData();
  },
  mounted() {
    document.title = "Contacts"
  },
  methods: {
    filterChange: function (ev) {
      this.filter = ev.filter;
    },
    getData: function () {
      //this.gridData
      NProgress.start()
      Service.getAllContactData()
        .then((response) => {
          this.customers = response[0].data;
          this.sites = response[1].data;
          this.accounts = response[2].data;
          this.gridData = response[3].data;
          this.loaded = true;
          console.log(this.gridData)
          NProgress.done()
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
          NProgress.done()
        });
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      this.gridData = orderBy(this.gridData, this.sort);
    },
    dataStateChange: function (event) {
      console.log("dataStateChange...");
    },

    pageChange(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    itemChange: function (e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign({}, this.gridData[index], {
          [e.field]: e.value,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        e.dataItem[e.field] = e.value;
      }
    },
    insert() {
      const dataItem = { inEdit: true };
      const newCustomers = this.gridData.slice();
      newCustomers.unshift(dataItem);
      this.update(newCustomers, dataItem);
      this.gridData = newCustomers;
    },
    edit: function (e) {
      let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
      let updated = Object.assign({}, this.gridData[index], { inEdit: true });
      this.gridData.splice(index, 1, updated);
    },
    save: function (e) {
      let contactID = e.dataItem.id;
      let index = this.gridData.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(e.dataItem) ||
          (contactID && c.id === contactID)
      );
      let item = this.gridData[index];
      let updated = Object.assign(this.update(this.gridData.slice(), item), {
        inEdit: undefined,
      });

      // upsert logic... base off id.. if 0, then create... else update
      if (updated.id == undefined) {
        Service.createContact(updated)
          .then((response) => {
            Vue.$toast.success(`Contact created`, {
              position: "top-right",
              duration: 4000,
            });
            updated.id = response.data.id;

            this.gridData.splice(index, 1, updated);
          })
          .catch((error) => {
            Vue.$toast.error(`Error saving data`, { position: "top-right" });
            console.log(error);
          });
      } else {
        Service.updateContact(updated.id, updated)
          .then((response) => {
            Vue.$toast.success(`Contact updated`, {
              position: "top-right",
              duration: 4000,
            });
            this.gridData.splice(index, 1, updated);
          })
          .catch((error) => {
            Vue.$toast.error(`Error saving data`, { position: "top-right" });
            console.log(error);
          });
      }
    },
    update(data, item, remove) {
      let updated;
      let contactID = item.id;
      let index = data.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(item) ||
          (contactID && c.id === contactID)
      );
      if (index >= 0) {
        updated = Object.assign({}, item);
        data[index] = updated;
      } else {
        let id = 0;
        updated = Object.assign({}, item, { id: id });
        data.unshift(updated);
        index = 0;
      }

      if (remove) {
        data = data.splice(index, 1);
      }
      return data[index];
    },
    cancel(e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign(this.gridData[index], {
          inEdit: undefined,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        let index = this.gridData.findIndex(
          (p) => JSON.stringify(e.dataItem) === JSON.stringify(p)
        );

        this.gridData.splice(index, 1);
      }
    },
    remove(e) {
      e.dataItem.inEdit = undefined;
      Service.deleteContact(e.dataItem.id)
        .then((response) => {
          Vue.$toast.warning(`Contact removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.update(this.gridData, e.dataItem, true);
        })
        .catch((error) => {
          Vue.$toast.error(`Error deleting data`, { position: "top-right" });
          console.log(error);
        });
    },
    cancelChanges() {
      let editedItems = this.gridData.filter((p) => p.inEdit === true);
      if (editedItems.length) {
        editedItems.forEach((item) => {
          item.inEdit = undefined;
        });
      }
    },
    exportExcel() {
      saveExcel({
        data: this.dataToExport(),
        fileName: "contacts.xlsx",
        columns: this.columns
      });
    },
    dataToExport() {
      if (this.filter) {
        return this.result;
      } else {
        return this.gridData;
      }
    }
  },
};
</script>

<style>
  .k-toolbar {
    display: block;
  }

  .k-toolbar #add_new_btn {
    float: left;
  }

  .k-toolbar #cancel_changes_btn {
    margin-left: 1%;
  }

  .k-toolbar #export_btn {
    float: right;
  }
</style>